.home{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-image: url('../../../public/home.jpg');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}
