.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #eee;
}

.Form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
    flex: 1 0 90%;
    width: 100%;
    height: 100%;
    padding-top: 10vh;
}

.Form h1 {
    text-align: center;
    font-style: italic;
    background-image: linear-gradient(to bottom left, #5d9a3c, #2b5cee);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    padding-right: 2vw;
}

.LogInButtonDiv {
    display:flex;
    justify-content: center;
}

.Title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Error {
    color: red;
    padding: 10px 0 0 10px;
    font-weight: bold;
}
