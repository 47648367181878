.Modal {
    position: fixed;
    z-index: 500;
    background-color: #eee;
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.8s ease-in-out;
}

.ModalHide {
    composes: Modal;
    opacity: 0;
    transform:translate(-100vh , -100vh);

}

/* @media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}

@media (max-height: 600px) {
    .Modal {
        top: 5%;
        height:400px;
        overflow-y: scroll;
        padding-top : 5px;
        padding-bottom : 5px;
    }
} */
