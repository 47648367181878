.header {
    background-color: rgb(166, 233, 233, 0.76);
    text-align: center;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    height: 10vh;
}

.header h1 {
    font-style: italic;
    font-weight: 600;
    background-image: linear-gradient(to bottom left, #553c9a, #ee4b2b);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}
