.Tasks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99.8vw;
    height: 83vh;
    margin-left: 2vh;
    margin-right: 2vh;
    background-color: #eee;
    overflow-y: scroll;
    padding-top: 1vh;
    padding-bottom: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
}

.TasksLists {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    margin-top: 2vh;
}

.DisconnectButton {
    display:flex;
    justify-content: center;
    height: 10vh;
}

.Title {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 3vh;
}

.DivTitle {
    justify-self: center;
    flex: 1;
}

.DivSelection {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.DivSelection button {
    margin: 1vh 0.5vw !important;
}

.DivButtonNewTask {
    justify-self: flex-end;
}

.Title span {
    background-color: transparent;
    padding: 1vh 1vw;
    font-weight: 800;
    font-style: italic;
}

.imgDisabled {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -moz-user-drag: none;
    -khtml-user-drag: none;
    -webkit-user-drag: none;
    opacity: 0.3;
    cursor: not-allowed;
}

.imgEnabled {
    cursor: pointer;
    opacity: 1;
}

@media screen and (max-width: 575px) {
    .Title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .DivButtonNewTask {
        margin-top: 2vh;
    }
    .DivTitle {
        order : 1;
    }
    .DivSelection{
        order: 2;
        margin-top: 2vh;
    }
    .DivButtonNewTask {
        order: 3;
    }

}

td span {
    display: none;
}

.Error {
    color: red;
    padding: 10px 0 20px 10px;
    font-weight: bold;
}
