.Confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5vw;
    margin-right: 5vw;
}

.Title {
    margin-bottom: 4vh;
    font-weight: 900;
    font-size: x-large;
    border: 1px black solid;
    padding: 0.75vh 2vw;
    background-color: #ccc;
}

.Text {
    font-size: medium;
    font-style: italic;
    margin-bottom: 4vh;
    font-size: large;
}

.Buttons {
    display: flex;
    flex-direction: row;
    justify-content:center ;
    flex-wrap: wrap;
}
