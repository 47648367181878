.main {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-content: stretch;
    align-items: center;
    width: 100%;
    height: 85vh;
    position: relative;
}
